import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { ReactComponent as LoadingIcon } from "../../assets/svgs/loading-icon.svg";
import ProductCategoryField from "./ProductCategoryField";
import { addProduct } from "../../services/product.service";
import { useMutation, useQueryClient } from "react-query";
import useAuthStore from "../../authStore";
import { toast } from "react-toastify";
import ProductCategoryType from "./ProductCategoryType";

interface Props {
  cid: number;
  open: boolean;
  handleClose: () => void;
  categoryData:
    | {
        id: number;
        name: string;
        description: string | null;
        isActive: boolean;
        updatedAt: string;
        createdAt: string;
        fields: { id: number; name: string }[];
        types: { id: number; name: string }[];
      }[]
    | undefined;
  brandData: { id: number; name: string }[];
}

const AddProduct: React.FC<Props> = ({
  cid,
  open,
  handleClose,
  categoryData,
  brandData,
}) => {
  const [name, setName] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [price, setPrice] = useState("");
  const [validFromDate, setValidFromDate] = useState("");
  const [validToDate, setValidToDate] = useState("");
  const [remark, setRemark] = useState("");
  const [brandId, setBrandId] = useState<number>(-1);
  const [categoryId, setCategoryId] = useState<number>(-1);
  const [fieldDetail, setFieldDetail] = useState<
    {
      detail: string;
      fieldId: number;
    }[]
  >([]);
  const [typeDetail, setTypeDetail] = useState<
    {
      detailMin: string;
      detailMax: string;
      detailUnit: string;
      typeId: number;
    }[]
  >([]);
  const [errCat, setErrCat] = useState(false);
  const [errBrand, setErrBrand] = useState(false);
  const [errTarget, setErrTarget] = useState("");

  useEffect(() => {
    setCategoryId(cid);
  }, [cid]);

  const user = useAuthStore((state) => state.user);

  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(addProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries("products");
      setName("");
      setReferenceNo("");
      setPrice("");
      setValidFromDate("");
      setValidToDate("");
      setRemark("");
      // setBrandId(-1);
      // setCategoryId(-1);
      setFieldDetail([]);
      setErrCat(false);
      setErrBrand(false);
      setErrTarget("");
      handleClose();
    },
    onError: (err: any) => {
      const field = err.response.data.errorField;
      let message = "";
      if (field === "referenceNo_unique") {
        message = "Ref No. has already been used !";
        setErrTarget("referenceNo");
      } else if (field === "name_unique") {
        message = "Name has already been used !";
        setErrTarget("name");
      }
      toast.error(`${message ? message : "Technical Fault, contact Admin !"}`, {
        position: "top-center",
        autoClose: 2200,
      });
    },
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (brandId === -1 && categoryId === -1) {
      setErrBrand(true);
      setErrCat(true);
    } else if (brandId === -1) {
      setErrBrand(true);
    } else if (categoryId === -1) {
      setErrCat(true);
    } else {
      const formData = {
        name,
        referenceNo,
        price: +price,
        validFromDate: validFromDate ? new Date(validFromDate) : null,
        validToDate: validToDate ? new Date(validToDate) : null,
        remark,
        brandId,
        categoryId,
        fieldDetails: { createMany: { data: fieldDetail } },
        typeDetails: { createMany: { data: typeDetail } },
        authorId: user.id,
      };
      mutate(formData);
    }
  };

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <form
        onSubmit={handleSubmit}
        className="flex h-full flex-col focus:outline-none"
        style={{ width: "630px" }}
      >
        <div className="flex-1 p-5 overflow-y-auto">
          <p className="text-blue-500 font-medium text-3xl mb-5">
            Add new Product
          </p>
          <div className="space-y-5">
            <TextField
              inputProps={{ maxLength: 191 }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
              className="w-full"
              id="pname"
              label="Product Name"
              variant="outlined"
              required
              error={errTarget === "name"}
            />
            <TextField
              inputProps={{ maxLength: 191 }}
              type="number"
              value={referenceNo}
              onChange={(e) => setReferenceNo(e.target.value)}
              className="w-full"
              id="refno"
              label="References No."
              variant="outlined"
              required
              error={errTarget === "referenceNo"}
            />
            <div className="flex space-x-5">
              <TextField
                inputProps={{ maxLength: 191 }}
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                className="w-full"
                id="remark"
                label="Remark"
                variant="outlined"
              />
              <TextField
                inputProps={{ maxLength: 191 }}
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className="w-full"
                id="price"
                label="Price"
                variant="outlined"
              />
            </div>
            <div className="flex space-x-5">
              <div className="flex flex-col justify-center items-center space-y-0.5 text-gray-600 w-full">
                <p>Start Valid Date</p>
                <input
                  value={validFromDate}
                  onChange={(e) => setValidFromDate(e.target.value)}
                  type="date"
                  name="fromDate"
                  id="fromDate"
                  className="focus:outline-none border px-3 py-3.5 rounded border-gray-300 w-full text-gray-600"
                />
              </div>
              <div className="flex flex-col justify-center items-center space-y-0.5 text-gray-600 w-full">
                <p>End Valid Date</p>
                <input
                  value={validToDate}
                  onChange={(e) => setValidToDate(e.target.value)}
                  type="date"
                  name="endDate"
                  id="endDate"
                  className="focus:outline-none border px-3 py-3.5 rounded border-gray-300 w-full text-gray-600"
                />
              </div>
            </div>
            <div className="flex space-x-5">
              <select
                value={brandId}
                onChange={(e) => {
                  setBrandId(parseInt(e.target.value));
                  setErrBrand(false);
                }}
                id="brand"
                className={`py-3.5 px-3 w-full transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 capitalize form-select text-gray-600 ${
                  errBrand && "ring ring-red-400"
                }`}
              >
                <option value={-1} disabled>
                  Product Brand *
                </option>
                {brandData &&
                  brandData.map((brand, i) => {
                    return (
                      <option value={brand.id} key={i}>
                        {brand.name}
                      </option>
                    );
                  })}
              </select>
              <select
                value={categoryId}
                onChange={(e) => {
                  setCategoryId(parseInt(e.target.value));
                  setErrCat(false);
                }}
                id="cat"
                className={`py-3.5 px-3 w-full transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 capitalize form-select text-gray-600 ${
                  errCat && "ring ring-red-400"
                }`}
              >
                <option value={-1} disabled>
                  Product Category *
                </option>
                {categoryData &&
                  categoryData.map((category, i) => {
                    return (
                      <option value={category.id} key={i}>
                        {category.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          {categoryId !== -1 && (
            <div>
              <ProductCategoryType
                types={
                  categoryData &&
                  categoryData.filter((cat) => cat.id === categoryId)[0].types
                }
                setTypeDetail={setTypeDetail}
              />
              <ProductCategoryField
                fields={
                  categoryData &&
                  categoryData.filter((cat) => cat.id === categoryId)[0].fields
                }
                setFieldDetail={setFieldDetail}
              />
            </div>
          )}
        </div>
        <div className="h-16 w-full bg-gray-100 flex justify-end items-center px-5 space-x-4">
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            className="focus:outline-none"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            type="submit"
            variant="contained"
            color="primary"
            className="focus:outline-none"
            startIcon={<SaveIcon />}
          >
            {isLoading ? (
              <LoadingIcon className="text-blue-600 animate-spin h-5 w-5" />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

export default AddProduct;
