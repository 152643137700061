import React, { ReactElement, useState } from "react";
import { Button } from "@material-ui/core";
import { ReactComponent as AddBoxIcon } from "../assets/svgs/addbox-icon.svg";
import CategoryTable from "../components/category/CategoryTable";
import AddCategoryModal from "../components/category/AddCategoryModal";
import EditCategoryModal from "../components/category/EditCategoryModal";
import { CategoryDetail } from "../types/category.type";

const Category: React.FC = (): ReactElement => {
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState<CategoryDetail | null>(null);

  const handleEdit = (detail: CategoryDetail) => {
    setEditData(detail);
    setEditOpen(true);
  };

  return (
    <div className="h-full w-full px-10 text-gray-700">
      <AddCategoryModal open={addOpen} handleClose={() => setAddOpen(false)} />
      <EditCategoryModal
        open={editOpen}
        handleClose={() => setEditOpen(false)}
        detail={editData}
      />
      <div className="w-full flex justify-between py-6 items-center">
        <p className="text-3xl text-blue-500 font-medium select-none">
          Product Category
        </p>
        <Button
          onClick={() => setAddOpen(true)}
          variant="contained"
          className=" focus:outline-none"
          color="primary"
          endIcon={<AddBoxIcon />}
        >
          ADD CATEGORY
        </Button>
      </div>
      <CategoryTable editFunc={handleEdit} />
    </div>
  );
};

export default Category;
