import React, { ReactElement, useState } from "react";
import { Button } from "@material-ui/core";
import AddBrandModal from "../components/brand/AddBrandModal";
import BrandTable from "../components/brand/BrandTable";
import { ReactComponent as AddBoxIcon } from "../assets/svgs/addbox-icon.svg";
import EditBrandModal from "../components/brand/EditBrandModal";
import { BrandDetail } from "../types/brand.type";

const Brand: React.FC = (): ReactElement => {
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState<BrandDetail | null>(null);

  const handleEdit = (detail: BrandDetail) => {
    setEditData(detail);
    setEditOpen(true);
  };

  return (
    <div className="h-full w-full px-10 text-gray-700">
      <AddBrandModal open={addOpen} handleClose={() => setAddOpen(false)} />
      <EditBrandModal
        open={editOpen}
        handleClose={() => setEditOpen(false)}
        detail={editData}
      />
      <div className="w-full flex justify-between py-6 items-center">
        <p className="text-3xl text-blue-500 font-medium">Brand List</p>
        <Button
          onClick={() => setAddOpen(true)}
          variant="contained"
          className=" focus:outline-none"
          color="primary"
          endIcon={<AddBoxIcon />}
        >
          ADD BRAND
        </Button>
      </div>
      <BrandTable editFunc={handleEdit} />
    </div>
  );
};

export default Brand;
