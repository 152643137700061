import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import TextField from "@material-ui/core/TextField";
import { Button, Switch } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { ReactComponent as LoadingIcon } from "../../assets/svgs/loading-icon.svg";
import ProductCategoryField from "./ProductCategoryField";
import { updateProduct, deleteProduct } from "../../services/product.service";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import formatDateInput from "../../utils/formatDateInput";
import ConfirmDeleteModal from "../ConfirmDeleteModal";
import { CategoryDetail } from "../../types/category.type";
import { ProductDetail } from "../../types/product.type";
import ProductCategoryType from "./ProductCategoryType";

interface Props {
  open: boolean;
  handleClose: () => void;
  categoryData: CategoryDetail | undefined;
  brandData: { id: number; name: string }[];
  detail: ProductDetail | undefined;
}

const EditProduct: React.FC<Props> = ({
  open,
  handleClose,
  categoryData,
  brandData,
  detail,
}) => {
  const [name, setName] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [price, setPrice] = useState("");
  const [validFromDate, setValidFromDate] = useState<any>("");
  const [validToDate, setValidToDate] = useState("");
  const [remark, setRemark] = useState("");
  const [brandId, setBrandId] = useState<number>(-1);
  const [fieldDetail, setFieldDetail] = useState<
    {
      id?: number;
      detail: string;
      fieldId: number;
    }[]
  >([]);
  const [typeDetail, setTypeDetail] = useState<
    {
      id?: number;
      detailMin: string;
      detailMax: string;
      detailUnit: string;
      typeId: number;
    }[]
  >([]);
  const [errBrand, setErrBrand] = useState(false);
  const [errTarget, setErrTarget] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [delModal, setDelModal] = useState(false);

  useEffect(() => {
    setName("");
    setReferenceNo("");
    setPrice("");
    setRemark("");
    setValidFromDate("");
    setValidToDate("");
    if (detail) {
      setBrandId(detail.brandId);
      setName(detail.name);
      setReferenceNo(detail.referenceNo);
      setPrice(detail.price || "");
      setRemark(detail.remark || "");
      setIsActive(detail.isActive || false);
      if (detail.validFromDate !== null) {
        setValidFromDate(formatDateInput(detail.validFromDate));
      }
      if (detail.validToDate !== null) {
        setValidToDate(formatDateInput(detail.validToDate));
      }
    }
  }, [detail]);

  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(updateProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries("products");
      setName("");
      setReferenceNo("");
      setPrice("");
      setValidFromDate("");
      setValidToDate("");
      setRemark("");
      setBrandId(-1);
      setFieldDetail([]);
      setErrBrand(false);
      setErrTarget("");
      handleClose();
    },
    onError: (err: any) => {
      const field = err.response.data.errorField;
      let message = "";
      if (field === "referenceNo_unique") {
        message = "Ref No. has already been used !";
        setErrTarget("referenceNo");
      } else if (field === "name_unique") {
        message = "Name has already been used !";
        setErrTarget("name");
      }
      toast.error(`${message ? message : "Technical Fault, contact Admin !"}`, {
        position: "top-center",
        autoClose: 2200,
      });
    },
  });

  const handleSubmit = (event: React.FormEvent) => {
    const id = detail && detail.id;
    event.preventDefault();
    if (brandId === -1) {
      setErrBrand(true);
    } else {
      const formData = {
        name,
        referenceNo,
        price: +price,
        validFromDate: validFromDate ? new Date(validFromDate) : null,
        validToDate: validToDate ? new Date(validToDate) : null,
        remark,
        brandId,
        isActive,
        typeDetails: {
          createMany: { data: typeDetail.filter((e) => !("id" in e)) },
          update: typeDetail
            .filter((e) => "id" in e)
            .map((ed) => {
              return {
                where: {
                  id: ed.id,
                },
                data: {
                  detailMin: ed.detailMin,
                  detailMax: ed.detailMax,
                  detailUnit: ed.detailUnit,
                },
              };
            }),
        },
        fieldDetails: {
          createMany: { data: fieldDetail.filter((e) => !("id" in e)) },
          update: fieldDetail
            .filter((e) => "id" in e)
            .map((ed) => {
              return {
                where: {
                  id: ed.id,
                },
                data: {
                  detail: ed.detail,
                },
              };
            }),
        },
      };
      mutate({ id, data: formData });
    }
  };

  const { isLoading: isDelLoad, mutate: mutateDel } = useMutation(
    deleteProduct,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("products");
        handleClose();
      },
      onError: () => {
        toast.error(`Technical Fault, contact Admin !`, {
          autoClose: 2200,
        });
      },
    }
  );

  const handleDelete = () => {
    setDelModal(false);
    if (detail && detail.id) mutateDel(detail.id);
  };

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <ConfirmDeleteModal
        delFunc={handleDelete}
        open={delModal}
        handleClose={() => setDelModal(false)}
      />
      <form
        onSubmit={handleSubmit}
        className="flex h-full flex-col focus:outline-none"
        style={{ width: "630px" }}
      >
        <div className="flex-1 p-5 overflow-y-auto">
          <div className="flex items-center justify-between mb-5">
            <p className="text-blue-500 font-medium text-2xl">Edit Product</p>
            <div className="flex items-center">
              <p
                className={`font-medium mr-1 ${
                  isActive ? "text-blue-500" : "text-red-500"
                }`}
              >
                {isActive ? "Active" : "Inactive"}
              </p>
              <Switch
                color="primary"
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
              />
            </div>
          </div>
          <div className="space-y-5">
            <TextField
              inputProps={{ maxLength: 191 }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full"
              id="pname"
              label="Product Name"
              variant="outlined"
              required
              error={errTarget === "name"}
            />
            <TextField
              inputProps={{ maxLength: 30 }}
              type="number"
              value={referenceNo}
              onChange={(e) => setReferenceNo(e.target.value)}
              className="w-full"
              id="refno"
              label="References No."
              variant="outlined"
              required
              error={errTarget === "referenceNo"}
            />
            <div className="flex space-x-5">
              <TextField
                inputProps={{ maxLength: 191 }}
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                className="w-full"
                id="remark"
                label="Remark"
                variant="outlined"
              />
              <TextField
                inputProps={{ maxLength: 191 }}
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className="w-full"
                id="price"
                label="Price"
                variant="outlined"
              />
            </div>
            <div className="flex space-x-5">
              <div className="flex flex-col justify-center items-center space-y-0.5 text-gray-600 w-full">
                <p>Start Valid Date</p>
                <input
                  value={validFromDate}
                  onChange={(e) => setValidFromDate(e.target.value)}
                  type="date"
                  name="fromDate"
                  id="fromDate"
                  className="focus:outline-none border px-3 py-3.5 rounded border-gray-300 w-full text-gray-600"
                />
              </div>
              <div className="flex flex-col justify-center items-center space-y-0.5 text-gray-600 w-full">
                <p>End Valid Date</p>
                <input
                  value={validToDate}
                  onChange={(e) => setValidToDate(e.target.value)}
                  type="date"
                  name="endDate"
                  id="endDate"
                  className="focus:outline-none border px-3 py-3.5 rounded border-gray-300 w-full text-gray-600"
                />
              </div>
            </div>
            <div className="flex space-x-5">
              <select
                value={brandId}
                onChange={(e) => {
                  setBrandId(parseInt(e.target.value));
                  setErrBrand(false);
                }}
                id="brand"
                className={`py-3.5 px-3 w-full transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 capitalize form-select text-gray-600 ${
                  errBrand && "ring ring-red-400"
                }`}
              >
                <option value={-1} disabled>
                  Product Brand *
                </option>
                {brandData &&
                  brandData.map((brand, i) => {
                    return (
                      <option value={brand.id} key={i}>
                        {brand.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          {
            <ProductCategoryType
              types={categoryData && categoryData.types}
              setTypeDetail={setTypeDetail}
              details={detail && detail.typeDetails}
            />
          }
          {
            <ProductCategoryField
              fields={categoryData && categoryData.fields}
              setFieldDetail={setFieldDetail}
              details={detail && detail.fieldDetails}
            />
          }
        </div>
        <div className="h-16 w-full bg-gray-100 rounded-b-md flex space-x-4 justify-between items-center px-5">
          <Button
            onClick={() => setDelModal(true)}
            color="secondary"
            variant="contained"
            className="focus:outline-none"
          >
            {isDelLoad ? (
              <LoadingIcon className="text-white animate-spin h-5 w-5" />
            ) : (
              "Delete"
            )}
          </Button>
          <div className="space-x-5">
            <Button
              onClick={handleClose}
              color="primary"
              variant="contained"
              className="focus:outline-none"
            >
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              color="primary"
              className="focus:outline-none"
              startIcon={<SaveIcon />}
            >
              {isLoading ? (
                <LoadingIcon className="text-blue-600 animate-spin h-5 w-5" />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </div>
      </form>
    </Drawer>
  );
};

export default EditProduct;
