import React, { ReactElement, useState } from "react";
import { useHistory } from "react-router";
import logo from "../assets/images/logo.png";
import useAuthStore from "../authStore";
import { ReactComponent as LoadingIcon } from "../assets/svgs/loading-icon.svg";
import { toast } from "react-toastify";

const Login: React.FC = (): ReactElement => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const loading = useAuthStore((state) => state.loading);
  const login = useAuthStore((state) => state.login);
  const history = useHistory();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    login(email, password)
      .then(() => {
        toast.success("You've been logged in successfully !", {
          autoClose: 2200,
          position: "top-center",
        });
        history.push("/");
      })
      .catch(() => {
        toast.error("The email or password is incorrect !", {
          autoClose: 2200,
        });
      });
  };

  return (
    <div>
      <div className="w-screen h-screen flex justify-center items-center bg-gray-100">
        <form
          className="p-10 bg-white rounded flex justify-center items-center flex-col shadow-md"
          onSubmit={handleSubmit}
        >
          <img src={logo} alt="logo" width={100} height={100} />
          <p className="mb-5 mt-2.5 text-xl text-gray-600 select-none">
            Kamonnat Management System
          </p>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
            type="email"
            name="email"
            className="mb-5 p-3 w-80 rounded border-2 outline-none focus:border-kai-blue"
            placeholder="Email"
            required
          />
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            name="password"
            className="mb-5 p-3 w-80 rounded border-2 outline-none focus:border-kai-blue"
            placeholder="Password"
            required
          />
          <button
            className="bg-kai-orange hover:bg-yellow-500 text-white font-bold p-2 rounded w-80 flex justify-center items-center"
            id="login"
            type="submit"
          >
            {loading ? (
              <span>
                <LoadingIcon className="text-white animate-spin h-5 w-5" />
              </span>
            ) : (
              <span>LOGIN</span>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
