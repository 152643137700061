import React, { ReactElement } from "react";
import useAuthStore from "../authStore";
import useVisible from "./useVisible";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";

const NavUserInfo: React.FC = (): ReactElement => {
  const user = useAuthStore((state) => state.user);
  const logout = useAuthStore((state) => state.logout);
  const { ref, isVisible, setIsVisible } = useVisible(false);

  return (
    <div className="h-full" ref={ref}>
      <div
        className="px-5 h-full flex justify-center items-center cursor-pointer border-l border-gray-300 select-none hover:bg-kai-white"
        // onMouseEnter={() => setIsVisible(true)}
        onClick={() => setIsVisible(!isVisible)}
      >
        <div
          className="flex justify-center items-center"
          style={{ fontSize: "2.65rem" }}
        >
          <AccountCircleTwoToneIcon fontSize="inherit" color="primary" />
        </div>
        <div className="flex flex-col text-sm mx-3.5 items-end">
          <p className="text-gray-600 font-semibold">{`${user.firstname} ${user.lastname}`}</p>
          <p className="text-gray-600 capitalize">
            {user.role ? user.role.name : "-"}
          </p>
        </div>
        <svg
          className={`h-8 text-blue-500 transform transition-transform ${
            isVisible && "rotate-180"
          }`}
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </div>
      {isVisible && (
        <div
          className="flex px-7 rounded-bl-md items-center h-12 cursor-pointer border border-gray-300 absolute w-full bg-white z-50 hover:bg-kai-white hover:text-red-500 text-gray-600"
          onClick={logout}
        >
          <svg
            className="h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
            ></path>
          </svg>
          <p className="ml-3.5 select-none text-sm">Logout</p>
        </div>
      )}
    </div>
  );
};

export default NavUserInfo;
