import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import axios from "axios";
import { API_URL } from "./utils/constants";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

axios.defaults.baseURL = API_URL;
const token = localStorage.getItem("token");
if (token) axios.defaults.headers.common["Authorization"] = token;

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
