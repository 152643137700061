import React, { ReactElement, useState, useEffect } from "react";
import * as api from "../../services/product.service";
import { useQuery } from "react-query";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import formatDate from "../../utils/formatDate";
import { ProductDynamicColumn } from "../../types/product.type";

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
});

const initColumn: ProductDynamicColumn[] = [
  // { id: "id", label: "PID", align: "center" },
  { id: "name", label: "Name", minWidth: 225 },
  { id: "referenceNo", label: "Reference No.", align: "center", minWidth: 130 },
  { id: "brand", label: "Brand", align: "center", minWidth: 125 },
  { id: "category", label: "Category", align: "center", minWidth: 125 },
  { id: "remark", label: "Remark", align: "center" },
  { id: "price", label: "Price", align: "center" },
  { id: "validFromDate", label: "Start Date", align: "center", minWidth: 100 },
  { id: "validToDate", label: "End Date", align: "center", minWidth: 100 },
  { id: "createdAt", label: "Created At", align: "center", minWidth: 100 },
  { id: "isActive", label: "Status", align: "center" },
];

interface Props {
  catData:
    | {
        id: number;
        name: string;
        description: string | null;
        isActive: boolean;
        updatedAt: string;
        createdAt: string;
        fields: { id: number; name: string }[];
        types: { id: number; name: string }[];
        _count: { products: number };
      }
    | undefined;
  editFunc: (detail: any) => void;
}

const ProductCategoryTable: React.FC<Props> = ({
  catData,
  editFunc,
}): ReactElement => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [columns, setColumns] = useState(initColumn);
  const [rows, setRows] = useState<any>([]);
  const [count, setCount] = useState(0);

  const { data } = useQuery(
    ["products", catData?.id, page, rowsPerPage],
    () =>
      api.getProducts({
        skip: page * rowsPerPage,
        take: rowsPerPage,
        catId: catData?.id,
      }),
    { keepPreviousData: true, staleTime: 5000 }
  );

  useEffect(() => {
    setColumns(initColumn);
    if (catData) {
      let dynamicType: ProductDynamicColumn[] = [];
      let dynamicField: ProductDynamicColumn[] = [];
      if (catData.types.length) {
        catData.types.forEach((t) => {
          dynamicType.push({
            id: t.id,
            label: t.name,
            align: "center",
            minWidth: 160,
          });
        });
      }
      if (catData.fields.length) {
        catData.fields.forEach((f) => {
          dynamicField.push({
            id: f.id,
            label: f.name,
            align: "center",
            minWidth: 340,
          });
        });
      }
      const [f1, f2, f3, ...f] = initColumn;
      setColumns([f1, f2, f3, ...dynamicType, ...dynamicField, ...f]);
    }
  }, [catData]);

  useEffect(() => {
    if (data) {
      setRows(data.data);
      setCount(data.count);
    }
  }, [data]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="w-full">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <p className="text-gray-600">{column.label}</p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any, i: number) => (
              <TableRow
                onClick={() => editFunc(row)}
                key={row.id}
                className={`${
                  i % 2 === 0 ? `bg-gray-50` : ""
                } hover:bg-blue-50 cursor-pointer`}
              >
                {/* <TableCell align="center">
                  <p className="text-gray-600">{row.id}</p>
                </TableCell> */}
                <TableCell>
                  <p className="text-gray-600">{row.name}</p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-gray-600">{row.referenceNo}</p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-gray-600">{row.brand && row.brand.name}</p>
                </TableCell>
                {catData &&
                  catData.types.map((t, i) => {
                    const detail = row.typeDetails.find(
                      (e: any) => e.typeId === t.id
                    );
                    if (detail) {
                      return (
                        <TableCell align="center" key={i}>
                          <p className="text-gray-600 break-all">
                            {detail.detailMin}
                            {detail.detailMax && ` - ${detail.detailMax}`}
                            {detail.detailUnit && ` ${detail.detailUnit}`}
                          </p>
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell align="center" key={i}>
                          <p className="text-gray-600 break-all">-</p>
                        </TableCell>
                      );
                    }
                  })}
                {catData &&
                  catData.fields.map((f, i) => {
                    const detail = row.fieldDetails.find(
                      (e: any) => e.fieldId === f.id
                    );
                    if (detail) {
                      return (
                        <TableCell align="center" key={i}>
                          <p className="text-gray-600 break-all">
                            {detail.detail}
                          </p>
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell align="center" key={i}>
                          <p className="text-gray-600 break-all">-</p>
                        </TableCell>
                      );
                    }
                  })}
                <TableCell align="center">
                  <p className="text-gray-600 break-all">
                    {row.category && row.category.name}
                  </p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-gray-600">{row.remark || "-"}</p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-gray-600">{row.price || "-"}</p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-gray-600">
                    {formatDate(row.validFromDate) || "-"}
                  </p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-gray-600">
                    {formatDate(row.validToDate) || "-"}
                  </p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-gray-600">{formatDate(row.createdAt)}</p>
                </TableCell>
                <TableCell align="center">
                  <p
                    className={`${
                      row.isActive ? "text-green-500" : "text-red-500"
                    } font-medium`}
                  >{`${row.isActive ? "Active" : "Inactive"}`}</p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <table className="w-full">
        <tbody>
          <tr>
            <TablePagination
              rowsPerPageOptions={[10, 15, 25]}
              colSpan={4}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </tr>
        </tbody>
      </table>

      <div className="h-7" />
    </div>
  );
};

export default ProductCategoryTable;
