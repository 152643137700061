import create from "zustand";
import axios from "axios";
import { toast } from "react-toastify";

interface AuthStore {
  token: string;
  user: any;
  loading: boolean;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
  checkAuth: () => void;
}

const useAuthStore = create<AuthStore>((set, get) => ({
  token: localStorage.getItem("token") || "",
  user: {
    firstname: "Loading..",
    lastname: "",
    role: "Loading..",
  },
  loading: false,
  login: (email, password) => {
    set({ loading: true });
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/login", { email, password })
        .then((res) => {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${res.data.access_token}`;
          localStorage.setItem("token", `Bearer ${res.data.access_token}`);
          set({ token: `Bearer ${res.data.access_token}` });
          set({ loading: false });
          resolve(true);
        })
        .catch((err) => {
          // console.log(err);
          set({ loading: false });
          reject(false);
        });
    });
  },
  logout: () => {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common["Authorization"];
    set({ user: {} });
    set({ token: "" });
    toast.error("You've been logged out !", {
      autoClose: 2200,
    });
  },
  checkAuth: () => {
    axios
      .get("/auth/me")
      .then((res) => {
        console.log(res.data);
        set({ user: res.data });
      })
      .catch(() => {
        get().logout();
      });
  },
}));

export default useAuthStore;
