import { Button, Modal, Switch, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SaveIcon from "@material-ui/icons/Save";
import { useMutation, useQueryClient } from "react-query";
import { updateBrand, deleteBrand } from "../../services/brand.service";
import { ReactComponent as LoadingIcon } from "../../assets/svgs/loading-icon.svg";
import { toast } from "react-toastify";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ConfirmDeleteModal from "../ConfirmDeleteModal";

interface Props {
  open: boolean;
  handleClose: () => void;
  detail: {
    id: number;
    name: string;
    description: string;
    imageUrl: string;
    phoneNumber: string;
    taxNo: string;
    createdAt: string;
    isActive: boolean;
  } | null;
}

const EditBrandModal: React.FC<Props> = ({ open, handleClose, detail }) => {
  const [errTarget, setErrTarget] = useState("");
  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState("");
  const [taxNo, setTaxNo] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [delModal, setDelModal] = useState(false);

  useEffect(() => {
    setName("");
    setImageUrl("");
    setDescription("");
    setTaxNo("");
    setPhoneNumber("");
    if (detail) {
      setName(detail.name || "");
      setImageUrl(detail.imageUrl || "");
      setDescription(detail.description || "");
      setTaxNo(detail.taxNo || "");
      setPhoneNumber(detail.phoneNumber || "");
      setIsActive(detail.isActive || false);
    }
  }, [detail]);

  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(updateBrand, {
    onSuccess: () => {
      queryClient.invalidateQueries("brands");
      setName("");
      setImageUrl("");
      setDescription("");
      setTaxNo("");
      setPhoneNumber("");
      setErrTarget("");
      handleClose();
    },
    onError: (err: any) => {
      const field = err.response.data.errorField;
      let message = "";
      if (field === "taxNo_unique") {
        message = "Tax No. has already been used !";
        setErrTarget("taxNo");
      } else if (field === "name_unique") {
        message = "Brand Name has already been used !";
        setErrTarget("name");
      }
      toast.error(`${message ? message : "Technical Fault, contact Admin !"}`, {
        autoClose: 2200,
      });
    },
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    mutate({
      id: detail && detail.id,
      brandData: { name, imageUrl, description, taxNo, phoneNumber, isActive },
    });
  };

  const { isLoading: isDelLoad, mutate: mutateDel } = useMutation(deleteBrand, {
    onSuccess: () => {
      queryClient.invalidateQueries("brands");
      handleClose();
    },
    onError: () => {
      toast.error(`Technical Fault, contact Admin !`, {
        autoClose: 2200,
      });
    },
  });

  const handleDelete = () => {
    setDelModal(false);
    if (detail && detail.id) mutateDel(detail.id);
  };

  return (
    <div>
      <ConfirmDeleteModal
        delFunc={handleDelete}
        open={delModal}
        handleClose={() => setDelModal(false)}
      />
      <Modal
        onClose={handleClose}
        open={open}
        className="flex justify-center items-center"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <form
            onSubmit={handleSubmit}
            style={{ width: "520px" }}
            className="bg-white rounded-md flex flex-col w-full focus:outline-none"
          >
            <div className="flex-1 bg-white w-full rounded-t-md p-5">
              <div className="flex items-center justify-between">
                <p className="text-blue-500 font-medium text-2xl">Edit Brand</p>
                <div className="flex items-center">
                  <p
                    className={`font-medium mr-1 ${
                      isActive ? "text-blue-500" : "text-red-500"
                    }`}
                  >
                    {isActive ? "Active" : "Inactive"}
                  </p>
                  <Switch
                    color="primary"
                    checked={isActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                  />
                </div>
              </div>
              <div className="pt-5 w-full space-y-5">
                <TextField
                  inputProps={{ maxLength: 191 }}
                  id="imgUrl"
                  label="Image Url"
                  variant="outlined"
                  className="w-full"
                  value={imageUrl}
                  onChange={(e) => setImageUrl(e.target.value)}
                />
                <TextField
                  inputProps={{ maxLength: 191 }}
                  id="name"
                  label="Brand Name"
                  variant="outlined"
                  className="w-full"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={errTarget === "name"}
                />
                <TextField
                  inputProps={{ maxLength: 30 }}
                  id="tax"
                  label="Tax No."
                  type="number"
                  variant="outlined"
                  className="w-full"
                  required
                  value={taxNo}
                  onChange={(e) => setTaxNo(e.target.value)}
                  error={errTarget === "taxNo"}
                />
                <TextField
                  id="phone"
                  label="Call Center Number"
                  type="number"
                  variant="outlined"
                  className="w-full"
                  required
                  inputProps={{ maxLength: 15 }}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <TextField
                  inputProps={{ maxLength: 500 }}
                  className="w-full"
                  id="description"
                  label="Description"
                  multiline
                  rows={3}
                  variant="outlined"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="h-16 w-full bg-gray-100 rounded-b-md flex space-x-4 justify-between items-center px-5">
              <Button
                onClick={() => setDelModal(true)}
                color="secondary"
                variant="contained"
                className="focus:outline-none"
              >
                {isDelLoad ? (
                  <LoadingIcon className="text-white animate-spin h-5 w-5" />
                ) : (
                  "Delete"
                )}
              </Button>
              <div className="space-x-5">
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="contained"
                  className="focus:outline-none"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isLoading}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="focus:outline-none"
                  startIcon={<SaveIcon />}
                >
                  {isLoading ? (
                    <LoadingIcon className="text-blue-600 animate-spin h-5 w-5" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </div>
          </form>
        </Fade>
      </Modal>
    </div>
  );
};

export default EditBrandModal;
