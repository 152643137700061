import axios from "axios";
import { BrandUpdateInput, BrandCreateInput } from "../types/brand.type";
import addQuery from "../utils/addQuery";

export const getBrands = (queryParams?: { [k: string]: any }) => {
  const url = addQuery("/brands", queryParams);
  return axios.get(url).then((res) => res.data);
};

export const addBrand = (brandData: BrandCreateInput) =>
  axios.post("/brands", brandData).then((res) => res.data);

export const updateBrand = (data: {
  id: number | null;
  brandData: BrandUpdateInput;
}) => axios.patch(`/brands/${data.id}`, data.brandData).then((res) => res.data);

export const deleteBrand = (id: number) =>
  axios.delete(`/brands/${id}`).then((res) => res.data);
