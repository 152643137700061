import React, { ReactElement, useState } from "react";
import { Button } from "@material-ui/core";
import { ReactComponent as AddBoxIcon } from "../assets/svgs/addbox-icon.svg";
import ProductTable from "../components/product/ProductTable";
import AddProduct from "../components/product/AddProduct";
import * as categoryService from "../services/category.service";
import * as brandService from "../services/brand.service";
import { useQuery } from "react-query";
import ProductCategoryTable from "../components/product/ProductCategoryTable";
import EditProduct from "../components/product/EditProduct";
import { ProductDetail } from "../types/product.type";

const Product: React.FC = (): ReactElement => {
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [catSelect, setCatSelect] = useState(-1);
  const [editData, setEditData] =
    useState<ProductDetail | undefined>(undefined);

  const { data: brandData } = useQuery("brands", () =>
    brandService.getBrands()
  );

  const { data: categoryData } = useQuery("categories", () =>
    categoryService.getCategories()
  );

  const handleEdit = (detail: ProductDetail) => {
    setEditData(detail);
    setEditOpen(true);
  };

  return (
    <div className="h-full w-full px-10 text-gray-700">
      <EditProduct
        open={editOpen}
        handleClose={() => setEditOpen(false)}
        categoryData={
          editData &&
          categoryData &&
          categoryData.data.find((c) => c.id === editData.category.id)
        }
        brandData={brandData && brandData.data}
        detail={editData}
      />
      <AddProduct
        cid={catSelect}
        open={addOpen}
        handleClose={() => setAddOpen(false)}
        categoryData={categoryData && categoryData.data}
        brandData={brandData && brandData.data}
      />
      <div className="w-full flex justify-between py-6 items-center">
        <p className="text-3xl text-blue-500 font-medium">Product List</p>
        <div className="flex items-center space-x-5">
          <select
            value={catSelect}
            onChange={(e) => setCatSelect(parseInt(e.target.value))}
            required
            id="productcatselect"
            className="transition duration-300 border border-gray-300 focus:border-transparent focus:outline-none focus:ring-1 focus:ring-gray-300 text-xs form-select h-8 m-0 rounded w-44"
          >
            <option value={-1}>All Cateogry</option>
            {categoryData &&
              categoryData.data.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.name}
                </option>
              ))}
          </select>
          <Button
            onClick={() => setAddOpen(true)}
            variant="contained"
            className="focus:outline-none"
            color="primary"
            endIcon={<AddBoxIcon />}
          >
            ADD Product
          </Button>
        </div>
      </div>
      {catSelect === -1 ? (
        <ProductTable editFunc={handleEdit} />
      ) : (
        <ProductCategoryTable
          catData={
            categoryData &&
            categoryData.data.filter((cat) => cat.id === catSelect)[0]
          }
          editFunc={handleEdit}
        />
      )}
    </div>
  );
};

export default Product;
