import React, { ReactElement } from "react";
import NavItem from "./NavItem";
import { FOOTER_TEXT } from "../utils/constants";

const Sidebar: React.FC = (): ReactElement => {
  return (
    <aside className="relative bg-blue-600 h-screen w-64 pt-4 flex flex-col justify-between shadow-2xl">
      <div>
        <div className="text-gray-100 select-none pl-6">
          <p className="text-2xl font-semibold">Kamonnat Ai</p>
          <p>Management System</p>
        </div>
        <div className="mt-8">
          <NavItem name="dashboard" path="/" />
          <NavItem name="brand" path="/brand" />
          <NavItem name="category" path="/category" />
          <NavItem name="products" path="/products" />
          <NavItem name="suggest app" path="/suggest-apps" />
          <NavItem name="users" path="/users" />
        </div>
      </div>
      <div className="bg-blue-700 text-gray-200 text-center py-5 flex items-center space-x-2 justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
          />
        </svg>
        <p className="select-none">2020-{FOOTER_TEXT}</p>
      </div>
    </aside>
  );
};

export default Sidebar;
