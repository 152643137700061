import React, { ReactElement, useEffect } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import useAuthStore from "./authStore";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import User from "./pages/User";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Brand from "./pages/Brand";
import Category from "./pages/Category";
import Product from "./pages/Product";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import SuggestApp from "./pages/SuggestApp";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3B82F6",
      dark: "#1D4ED8",
      contrastText: "#FEFEFE",
    },
    secondary: {
      main: "#f44336",
    },
  },
});

const App: React.FC = (): ReactElement => {
  const checkAuth = useAuthStore((state) => state.checkAuth);
  const token = useAuthStore((state) => state.token);

  useEffect(() => {
    if (token) {
      checkAuth();
    }
  }, [checkAuth, token]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ToastContainer />
        <Switch>
          <PublicRoute exact path="/login">
            <Login />
          </PublicRoute>
          <PrivateRoute exact path="/">
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute path="/brand">
            <Brand />
          </PrivateRoute>
          <PrivateRoute path="/category">
            <Category />
          </PrivateRoute>
          <PrivateRoute path="/products">
            <Product />
          </PrivateRoute>
          <PrivateRoute path="/suggest-apps">
            <SuggestApp />
          </PrivateRoute>
          <PrivateRoute path="/users">
            <User />
          </PrivateRoute>
          <Redirect to="/" />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
