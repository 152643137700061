import React, { ReactElement, useState, useEffect } from "react";
import * as api from "../../services/category.service";
import { useQuery } from "react-query";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import formatDate from "../../utils/formatDate";
import { CategoryColumn, CategoryDetail } from "../../types/category.type";

const columns: CategoryColumn[] = [
  // { id: "id", label: "CID", align: "center" },
  { id: "name", label: "Name" },
  { id: "description", label: "Description", align: "center" },
  { id: "_count", label: "Products", align: "center" },
  { id: "createdAt", label: "Created At", align: "center", minWidth: 100 },
  { id: "isActive", label: "Status", align: "center" },
];

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
});

interface Props {
  editFunc: (detail: any) => void;
}

const CategoryTable: React.FC<Props> = ({ editFunc }): ReactElement => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<CategoryDetail[]>([]);
  const [count, setCount] = useState(0);

  const { data } = useQuery(
    ["categories", page, rowsPerPage],
    () => api.getCategories({ skip: page * rowsPerPage, take: rowsPerPage }),
    { keepPreviousData: true, staleTime: 5000 }
  );

  useEffect(() => {
    if (data) {
      setRows(data.data);
      setCount(data.count);
    }
  }, [data]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="w-full">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <p className="text-gray-600">{column.label}</p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                onClick={() => editFunc(row)}
                key={row.id}
                className={`${
                  i % 2 === 0 ? `bg-gray-50` : ""
                } hover:bg-blue-50 cursor-pointer`}
              >
                {/* <TableCell align="center">
                  <p className="text-gray-600">{row.id}</p>
                </TableCell> */}
                <TableCell>
                  <p className="text-gray-600 overflow-clip break-all">
                    {row.name}
                  </p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-gray-600 break-all">
                    {row.description || "-"}
                  </p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-gray-600">
                    {row._count && row._count.products}
                  </p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-gray-600">{formatDate(row.createdAt)}</p>
                </TableCell>
                <TableCell align="center">
                  <p
                    className={`${
                      row.isActive ? "text-green-500" : "text-red-500"
                    } font-medium`}
                  >{`${row.isActive ? "Active" : "Inactive"}`}</p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 15, 25]}
                colSpan={6}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <div className="h-7" />
    </div>
  );
};

export default CategoryTable;
