import axios from "axios";
import addQuery from "../utils/addQuery";

export const getApps = (queryParams?: { [k: string]: any }) => {
  const url = addQuery("/suggestedApps", queryParams);
  return axios.get(url).then((res) => res.data);
};

export const getAppsCat = () => {
  return axios.get("/suggestedApps/categories").then((res) => res.data);
};

export const getAppsType = () => {
  return axios.get("/suggestedApps/types").then((res) => res.data);
};

export const addApp = (appData: any) =>
  axios.post("/suggestedApps", appData).then((res) => res.data);

export const updateApp = (data: { id: number | null; appData: any }) =>
  axios
    .patch(`/suggestedApps/${data.id}`, data.appData)
    .then((res) => res.data);

export const deleteApp = (id: number) =>
  axios
    .delete(`/suggestedApps/${id}`)
    .then((res) => res.data)
    .catch((e) => console.log(e.response.data));

export const suggestApp = (suggData: any) =>
  axios
    .post("/suggestedApps/suggestByCriteria", suggData)
    .then((res) => res.data);
