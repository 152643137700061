import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";

interface ITypeList {
  id?: number;
  typeId: number;
  detailMin: string;
  detailMax: string;
  detailUnit: string;
}

interface Props {
  types: { id: number; name: string }[] | undefined;
  setTypeDetail: React.Dispatch<React.SetStateAction<ITypeList[]>>;
  details?: {
    id: number;
    typeId: number;
    detailMin: string;
    detailMax: string;
    detailUnit: string;
  }[];
}

const ProductCategoryType: React.FC<Props> = ({
  types,
  setTypeDetail,
  details,
}) => {
  const [typeList, setTypeList] = useState<ITypeList[]>([]);

  useEffect(() => {
    setTypeList([]);
    if (types && types.length && details && details.length) {
      let typeSetup: ITypeList[] = [];
      types.forEach((e) => {
        const td = details.find((d) => d.typeId === e.id);
        if (td) {
          typeSetup.push({
            id: td.id,
            typeId: e.id,
            detailMin: td.detailMin,
            detailMax: td.detailMax,
            detailUnit: td.detailUnit,
          });
        } else {
          typeSetup.push({
            typeId: e.id,
            detailMin: "",
            detailMax: "",
            detailUnit: "",
          });
        }
      });
      setTypeList(typeSetup);
    } else if (types && types.length) {
      let typeSetup: ITypeList[] = [];
      types.forEach((e) => {
        typeSetup.push({
          typeId: e.id,
          detailMin: "",
          detailMax: "",
          detailUnit: "",
        });
      });
      setTypeList(typeSetup);
    } else setTypeList([]);
  }, [types, details]);

  const handleFieldChange = (
    e: any,
    index: number,
    target: "detailMin" | "detailMax" | "detailUnit"
  ) => {
    const { value } = e.target;
    const list = [...typeList];
    list[index][target] = value;
    setTypeList(list);
    setTypeDetail(list);
  };

  return (
    <>
      <div className="mt-3.5 w-full">
        {types && types.length ? (
          <>
            <p className="text-blue-500 font-medium">Category Product Type</p>
            <div className="mt-1 space-y-1.5">
              {typeList.length &&
                types.map((type, i) => (
                  <div key={i}>
                    <p className="text-gray-600 pl-0.5">{type.name}</p>
                    <div className="flex mt-1.5 items-center space-x-3 overflow-x-auto py-1.5">
                      <TextField
                        value={(typeList[i] && typeList[i].detailMin) || ""}
                        onChange={(e) => handleFieldChange(e, i, "detailMin")}
                        inputProps={{ maxLength: 65000 }}
                        className="w-full"
                        label="Value"
                        variant="outlined"
                      />
                      <p>-</p>
                      <TextField
                        value={(typeList[i] && typeList[i].detailMax) || ""}
                        onChange={(e) => handleFieldChange(e, i, "detailMax")}
                        inputProps={{ maxLength: 191 }}
                        className="w-full"
                        label="2nd Value"
                        variant="outlined"
                      />
                      <TextField
                        value={(typeList[i] && typeList[i].detailUnit) || ""}
                        onChange={(e) => handleFieldChange(e, i, "detailUnit")}
                        inputProps={{ maxLength: 191 }}
                        className="w-full"
                        label="Unit"
                        variant="outlined"
                      />
                    </div>
                  </div>
                ))}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default ProductCategoryType;
