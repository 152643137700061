import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import useAuthStore from "../authStore";

const PublicRoute: React.FC<RouteProps> = ({ children }) => {
  const token = useAuthStore((state) => state.token);
  if (token) return <Redirect to="/" />;
  return <Route>{children}</Route>;
};

export default PublicRoute;
