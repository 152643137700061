import { Button, Modal, TextField } from "@material-ui/core";
import React, { useState } from "react";
import SaveIcon from "@material-ui/icons/Save";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { suggestApp } from "../../services/app.service";
import { ReactComponent as LoadingIcon } from "../../assets/svgs/loading-icon.svg";

interface Props {
  id: number | undefined;
  open: boolean;
  handleClose: () => void;
}

const SuggestModal: React.FC<Props> = ({ open, handleClose, id }) => {
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const suggData: any = {
      appIds: [id],
      ageRange: [+iAge, +fAge],
      revenueRange: [+iRevenue, +fRevenue],
      expenseRange: [+iExpense, +fExpense],
      savingRagne: [+iSaving, +fSaving],
      genders: [gender],
      religions: [religion],
      occupations: [occupation],
    };
    if (!iAge && !fAge) delete suggData.ageRange;
    if (!iRevenue && !fRevenue) delete suggData.revenueRange;
    if (!iExpense && !fExpense) delete suggData.expenseRange;
    if (!iSaving && !fSaving) delete suggData.savingRagne;
    if (!gender) delete suggData.genders;
    if (!religion) delete suggData.religions;
    if (!occupation) delete suggData.occupations;
    await suggestApp(suggData);
    setLoading(false);
    handleClose();
  };

  const [loading, setLoading] = useState(false);

  const [iAge, setIAge] = useState("");
  const [fAge, setFAge] = useState("");

  const [iRevenue, setIRevenue] = useState("");
  const [fRevenue, setFRevenue] = useState("");

  const [iExpense, setIExpense] = useState("");
  const [fExpense, setFExpense] = useState("");

  const [iSaving, setISaving] = useState("");
  const [fSaving, setFSaving] = useState("");

  const [gender, setGender] = useState("");
  const [occupation, setOccupation] = useState("");
  const [religion, setReligion] = useState("");

  return (
    <div>
      <Modal
        onClose={handleClose}
        open={open}
        className="flex justify-center items-center"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <form
            onSubmit={handleSubmit}
            style={{ width: "550px" }}
            className="bg-white rounded-md flex flex-col w-full focus:outline-none"
          >
            <div className="flex-1 bg-white w-full rounded-t-md p-5">
              <p className="text-blue-500 font-medium text-2xl">Suggest App</p>
              <div className="pt-5 w-full space-y-5">
                <div className="flex items-center justify-center">
                  <TextField
                    type="number"
                    className="w-full"
                    id="agei"
                    label="from Age"
                    variant="outlined"
                    value={iAge}
                    onChange={(e) => setIAge(e.target.value)}
                    inputProps={{ maxLength: 2 }}
                  />
                  <p className="mx-3">-</p>
                  <TextField
                    type="number"
                    id="agef"
                    label="to Age"
                    variant="outlined"
                    className="w-full"
                    value={fAge}
                    onChange={(e) => setFAge(e.target.value)}
                    inputProps={{ maxLength: 2 }}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <TextField
                    type="number"
                    className="w-full"
                    id="revi"
                    label="from Revenue"
                    variant="outlined"
                    value={iRevenue}
                    onChange={(e) => setIRevenue(e.target.value)}
                    inputProps={{ maxLength: 2 }}
                  />
                  <p className="mx-3">-</p>
                  <TextField
                    type="number"
                    id="revf"
                    label="to Revenue"
                    variant="outlined"
                    className="w-full"
                    value={fRevenue}
                    onChange={(e) => setFRevenue(e.target.value)}
                    inputProps={{ maxLength: 2 }}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <TextField
                    type="number"
                    className="w-full"
                    id="exi"
                    label="from Expense"
                    variant="outlined"
                    value={iExpense}
                    onChange={(e) => setIExpense(e.target.value)}
                    inputProps={{ maxLength: 2 }}
                  />
                  <p className="mx-3">-</p>
                  <TextField
                    type="number"
                    id="exf"
                    label="to Expense"
                    variant="outlined"
                    className="w-full"
                    value={fExpense}
                    onChange={(e) => setFExpense(e.target.value)}
                    inputProps={{ maxLength: 2 }}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <TextField
                    type="number"
                    className="w-full"
                    id="savi"
                    label="from Saving"
                    variant="outlined"
                    value={iSaving}
                    onChange={(e) => setISaving(e.target.value)}
                    inputProps={{ maxLength: 2 }}
                  />
                  <p className="mx-3">-</p>
                  <TextField
                    type="number"
                    id="savf"
                    label="to Saving"
                    variant="outlined"
                    className="w-full"
                    value={fSaving}
                    onChange={(e) => setFSaving(e.target.value)}
                    inputProps={{ maxLength: 2 }}
                  />
                </div>
                <div className="flex w-full">
                  <select
                    placeholder="อาชีพ"
                    value={occupation}
                    onChange={(e) => setOccupation(e.target.value)}
                    id="occupation"
                    className="w-full text-md th-font form-select transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-kai-orange"
                  >
                    <option value="">ทุกอาชีพ</option>
                    <option value="รับราชการ">รับราชการ</option>
                    <option value="รัฐวิสาหกิจ">รัฐวิสาหกิจ</option>
                    <option value="พนักงานในบริษัทเอกชน">
                      พนักงานในบริษัทเอกชน
                    </option>
                    <option value="บุคลากรทางการแพทย์ เช่น แพทย์ พยาบาล">
                      บุคลากรทางการแพทย์ เช่น แพทย์ พยาบาล
                    </option>
                    <option value="เจ้าของกิจการ/ธุรกิจส่วนตัว">
                      เจ้าของกิจการ/ธุรกิจส่วนตัว
                    </option>
                    <option value="รับจ้าง/อาชีพอิสระ (freelance)">
                      รับจ้าง/อาชีพอิสระ (freelance)
                    </option>
                    <option value="ค้าขาย/เกษตกร">ค้าขาย/เกษตกร</option>
                    <option value="นักเรียนรักศึกษา">น้กเรียน/นักศึกษา</option>
                    <option value="อื่น ๆ">อื่น ๆ</option>
                  </select>
                </div>

                <div className="flex w-full">
                  <div className="flex-1 mr-5">
                    <select
                      placeholder="ศาสนา"
                      value={religion}
                      onChange={(e) => setReligion(e.target.value)}
                      // style={{ padding: "11px" }}
                      id="religion"
                      className="w-full text-md th-font form-select transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-kai-orange"
                    >
                      <option value="">ทุกศาสนา</option>
                      <option value="พุทธ">พุทธ</option>
                      <option value="คริสต์">คริสต์</option>
                      <option value="อิสลาม">อิสลาม</option>
                      <option value="อื่น ๆ">อื่น ๆ</option>
                    </select>
                  </div>

                  <div className="flex-1">
                    <select
                      placeholder="เพศ"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      // style={{ padding: "11px" }}
                      id="gender"
                      className="w-full text-md th-font form-select transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-kai-orange"
                    >
                      <option value="">ทุกเพศ</option>
                      <option value="male">ชาย</option>
                      <option value="female">หญิง</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-16 w-full bg-gray-100 rounded-b-md flex justify-end space-x-4 items-center px-5">
              <Button
                onClick={handleClose}
                color="primary"
                variant="contained"
                className="focus:outline-none"
              >
                Cancel
              </Button>
              <Button
                // disabled={isLoading}
                type="submit"
                variant="contained"
                color="primary"
                className="focus:outline-none"
                startIcon={<SaveIcon />}
              >
                {loading ? (
                  <LoadingIcon className="text-blue-600 animate-spin h-5 w-5" />
                ) : (
                  "Send"
                )}
              </Button>
            </div>
          </form>
        </Fade>
      </Modal>
    </div>
  );
};

export default SuggestModal;
