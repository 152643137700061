const formatDateInput = (date: string) => {
  const fDate = new Date(date)
    .toLocaleString()
    .split(", ")[0]
    .replaceAll("/", "-")
    .split("-");
  return `${fDate[2]}-${+fDate[0] > 9 ? fDate[0] : "0" + fDate[0]}-${
    +fDate[1] > 9 ? fDate[1] : "0" + fDate[1]
  }`;
};

export default formatDateInput;
