import { Button, Modal, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SaveIcon from "@material-ui/icons/Save";
import { useMutation, useQueryClient } from "react-query";
import { ReactComponent as LoadingIcon } from "../../assets/svgs/loading-icon.svg";
import { toast } from "react-toastify";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ConfirmDeleteModal from "../ConfirmDeleteModal";
import { updateApp, deleteApp } from "../../services/app.service";
import SuggestModal from "./SuggestModal";

interface Props {
  open: boolean;
  handleClose: () => void;
  detail: any;
  appCategories: any;
  appTypes: any;
}

const EditAppModal: React.FC<Props> = ({
  open,
  handleClose,
  detail,
  appCategories,
  appTypes,
}) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [errTarget, setErrTarget] = useState("");
  const [appName, setAppName] = useState("");
  const [iosLink, setIosLink] = useState("");
  const [androidLink, setAndroidLink] = useState("");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [type, setType] = useState<string | undefined>("");
  const [category, setCategory] = useState<string | undefined>("");
  const [delModal, setDelModal] = useState(false);
  const [sugModal, setSugModal] = useState(false);

  useEffect(() => {
    setId(undefined);
    setAppName("");
    setIosLink("");
    setAndroidLink("");
    setDescription("");
    setImageUrl("");
    setType("");
    setCategory("");
    if (detail) {
      setId(detail.id || undefined);
      setAppName(detail.appName || "");
      setIosLink(detail.iosLink || "");
      setAndroidLink(detail.androidLink || "");
      setDescription(detail.description || "");
      setImageUrl(detail.imageUrl || "");
      setType(detail.type || "");
      setCategory(detail.category || "");
    }
  }, [detail]);

  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(updateApp, {
    onSuccess: () => {
      queryClient.invalidateQueries("apps");
      setAppName("");
      setIosLink("");
      setAndroidLink("");
      setDescription("");
      setImageUrl("");
      setType("");
      setCategory("");
      handleClose();
    },
    onError: (err: any) => {
      const field = err.response.data.errorField;
      let message = "";
      if (field === "taxNo_unique") {
        message = "Tax No. has already been used !";
        setErrTarget("taxNo");
      } else if (field === "name_unique") {
        message = "Brand Name has already been used !";
        setErrTarget("name");
      }
      toast.error(`${message ? message : "Technical Fault, contact Admin !"}`, {
        autoClose: 2200,
      });
    },
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    mutate({
      id: detail && detail.id,
      appData: {
        appName,
        iosLink,
        androidLink,
        description,
        imageUrl,
        type,
        category,
      },
    });
  };

  const { isLoading: isDelLoad, mutate: mutateDel } = useMutation(deleteApp, {
    onSuccess: () => {
      queryClient.invalidateQueries("apps");
      handleClose();
    },
    onError: () => {
      toast.error(`Technical Fault, contact Admin !`, {
        autoClose: 2200,
      });
    },
  });

  const handleDelete = () => {
    setDelModal(false);
    if (detail && detail.id) mutateDel(detail.id);
  };

  return (
    <div>
      <SuggestModal
        open={sugModal}
        handleClose={() => setSugModal(false)}
        id={id}
      />
      <ConfirmDeleteModal
        delFunc={handleDelete}
        open={delModal}
        handleClose={() => setDelModal(false)}
      />
      <Modal
        onClose={handleClose}
        open={open}
        className="flex justify-center items-center"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <form
            onSubmit={handleSubmit}
            style={{ width: "520px" }}
            className="bg-white rounded-md flex flex-col w-full focus:outline-none"
          >
            <div className="flex-1 bg-white w-full rounded-t-md p-5">
              <div className="flex items-center justify-between">
                <p className="text-blue-500 font-medium text-2xl">Edit App</p>
                <Button
                  color="primary"
                  variant="contained"
                  className="focus:outline-none"
                  onClick={() => setSugModal(true)}
                >
                  Suggest App
                </Button>
              </div>
              <div className="pt-5 w-full space-y-5">
                <TextField
                  id="appName"
                  label="App Name"
                  variant="outlined"
                  className="w-full"
                  autoFocus
                  required
                  value={appName}
                  onChange={(e) => setAppName(e.target.value)}
                  error={errTarget === "name"}
                  inputProps={{ maxLength: 191 }}
                />
                <TextField
                  id="iosLink"
                  label="iOS Link"
                  variant="outlined"
                  className="w-full"
                  required
                  value={iosLink}
                  onChange={(e) => setIosLink(e.target.value)}
                  error={errTarget === "name"}
                  inputProps={{ maxLength: 191 }}
                />
                <TextField
                  id="androidLink"
                  label="Android Link"
                  variant="outlined"
                  className="w-full"
                  required
                  value={androidLink}
                  onChange={(e) => setAndroidLink(e.target.value)}
                  error={errTarget === "name"}
                  inputProps={{ maxLength: 191 }}
                />
                <TextField
                  className="w-full"
                  id="description"
                  label="Description"
                  multiline
                  rows={3}
                  variant="outlined"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  inputProps={{ maxLength: 500 }}
                />
                <TextField
                  id="imageUrl"
                  label="Image Url"
                  variant="outlined"
                  className="w-full"
                  required
                  value={imageUrl}
                  onChange={(e) => setImageUrl(e.target.value)}
                  error={errTarget === "name"}
                  inputProps={{ maxLength: 191 }}
                />
                <select
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                    // setErrBrand(false);
                  }}
                  id="type"
                  className={`py-3.5 px-3 w-full transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 capitalize form-select text-gray-600`}
                  //   className={`py-3.5 px-3 w-full transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 capitalize form-select text-gray-600 ${
                  //     errBrand && "ring ring-red-400"
                  //   }`}
                >
                  {appTypes &&
                    appTypes.map((type: any, i: number) => {
                      return (
                        <option value={type} key={i}>
                          {type}
                        </option>
                      );
                    })}
                </select>
                <select
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    // setErrBrand(false);
                  }}
                  id="cat"
                  className={`py-3.5 px-3 w-full transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 capitalize form-select text-gray-600`}
                  //   className={`py-3.5 px-3 w-full transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 capitalize form-select text-gray-600 ${
                  //     errBrand && "ring ring-red-400"
                  //   }`}
                >
                  {appCategories &&
                    appCategories.map((cat: any, i: number) => {
                      return (
                        <option value={cat} key={i}>
                          {cat}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="h-16 w-full bg-gray-100 rounded-b-md flex space-x-4 justify-between items-center px-5">
              <Button
                onClick={() => setDelModal(true)}
                color="secondary"
                variant="contained"
                className="focus:outline-none"
              >
                {isDelLoad ? (
                  <LoadingIcon className="text-white animate-spin h-5 w-5" />
                ) : (
                  "Delete"
                )}
              </Button>
              <div className="space-x-5">
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="contained"
                  className="focus:outline-none"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isLoading}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="focus:outline-none"
                  startIcon={<SaveIcon />}
                >
                  {isLoading ? (
                    <LoadingIcon className="text-blue-600 animate-spin h-5 w-5" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </div>
          </form>
        </Fade>
      </Modal>
    </div>
  );
};

export default EditAppModal;
