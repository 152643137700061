import {
  Button,
  IconButton,
  ListItemSecondaryAction,
  TextField,
  ListItemText,
} from "@material-ui/core";
import React, { useState } from "react";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

interface Props {
  types: { name: string }[];
  setTypes: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
      }[]
    >
  >;
}

const CategoryTypeList: React.FC<Props> = ({ types, setTypes }) => {
  const [newType, setNewType] = useState("");
  const handleDelete = (name: string) => {
    const filteredDelete = types.filter((item) => item.name !== name);
    setTypes(filteredDelete);
  };
  const handleAdd = () => {
    if (!newType) return;
    if (types.some((t) => t.name === newType)) return;
    const fieldObj = { name: newType };
    setTypes((prevState) => [...prevState, fieldObj]);
    setNewType("");
  };
  return (
    <div className="flex-1 overflow-x-scroll">
      <p className="text-blue-500 font-medium">Category Product's Type</p>
      <div className="mt-3 flex space-x-3 items-center mb-4">
        <TextField
          id="tfield"
          label="New Field"
          variant="outlined"
          className="w-full"
          placeholder="Product Field eg. coverage, etc"
          value={newType}
          inputProps={{ maxLength: 191 }}
          onChange={(e) => setNewType(e.target.value)}
        />
        <Button
          onClick={handleAdd}
          color="primary"
          variant="contained"
          className="focus:outline-none h-10"
          startIcon={<AddCircleOutlineOutlinedIcon />}
        >
          ADD
        </Button>
      </div>
      <List
        dense={true}
        className="h-40 overflow-y-auto bg-gray-100 py-2  rounded-md"
      >
        {types
          .map((t, i) => (
            <ListItem key={i}>
              <ListItemText primary={t.name} />
              <ListItemSecondaryAction>
                {"id" in t ? null : (
                  <IconButton
                    onClick={() => handleDelete(t.name)}
                    edge="end"
                    aria-label="delete"
                    className="focus:outline-none"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))
          .reverse()}
      </List>
    </div>
  );
};

export default CategoryTypeList;
