// import { Button } from "@material-ui/core";
import React, { ReactElement } from "react";
import UserTable from "../components/user/UserTable";

const User: React.FC = (): ReactElement => {
  return (
    <div className="h-full w-full px-10 text-gray-700">
      <div className="w-full flex justify-between py-6 items-center">
        <p className="text-3xl text-blue-500 font-medium select-none">
          User List
        </p>
        {/* <Button
          variant="contained"
          className=" focus:outline-none"
          color="primary"
          endIcon={
            <svg
              className="h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
            </svg>
          }
        >
          ADD USER
        </Button> */}
      </div>
      <UserTable />
    </div>
  );
};

export default User;
