import { Button, Modal, TextField } from "@material-ui/core";
import React, { useState } from "react";
import SaveIcon from "@material-ui/icons/Save";
import { useMutation, useQueryClient } from "react-query";
import { ReactComponent as LoadingIcon } from "../../assets/svgs/loading-icon.svg";
import { toast } from "react-toastify";
import { addCategory } from "../../services/category.service";
import CategoryFieldList from "./CategoryFieldList";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CategoryTypeList from "./CategoryTypeList";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const AddCategoryModal: React.FC<Props> = ({ open, handleClose }) => {
  const [errTarget, setErrTarget] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [fields, setFields] = useState<{ name: string }[]>([]);
  const [types, setTypes] = useState<{ name: string }[]>([]);

  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(addCategory, {
    onSuccess: () => {
      setName("");
      setDescription("");
      setErrTarget("");
      queryClient.invalidateQueries("categories");
      handleClose();
    },
    onError: (err: any) => {
      const field = err.response.data.errorField ? true : false;
      let message;
      if (field) {
        message = "Category has already been created !";
        setErrTarget("name");
      }
      toast.error(`${message ? message : "Technical Fault, contact Admin !"}`, {
        autoClose: 2200,
      });
    },
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    mutate({ name, description, fields, types });
  };

  return (
    <div>
      <Modal
        onClose={handleClose}
        open={open}
        className="flex justify-center items-center"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <form
            onSubmit={handleSubmit}
            style={{ width: "850px" }}
            className="bg-white rounded-md flex flex-col w-full focus:outline-none"
          >
            <div className="flex-1 bg-white w-full rounded-t-md p-5">
              <p className="text-blue-500 font-medium text-2xl">
                Add new Category
              </p>
              <div className="pt-5 w-full space-y-5">
                <TextField
                  id="name"
                  label="Category Name"
                  variant="outlined"
                  className="w-full"
                  autoFocus
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={errTarget === "name"}
                  inputProps={{ maxLength: 191 }}
                />
                <TextField
                  className="w-full"
                  id="description"
                  label="Description"
                  multiline
                  rows={3}
                  variant="outlined"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  inputProps={{ maxLength: 500 }}
                />
                <div className="flex w-full space-x-5">
                  <CategoryTypeList types={types} setTypes={setTypes} />
                  <CategoryFieldList fields={fields} setFields={setFields} />
                </div>
              </div>
            </div>
            <div className="h-16 w-full bg-gray-100 rounded-b-md flex justify-end space-x-4 items-center px-5">
              <Button
                onClick={handleClose}
                color="primary"
                variant="contained"
                className="focus:outline-none"
              >
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                type="submit"
                variant="contained"
                color="primary"
                className="focus:outline-none"
                startIcon={<SaveIcon />}
              >
                {isLoading ? (
                  <LoadingIcon className="text-blue-600 animate-spin h-5 w-5" />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </form>
        </Fade>
      </Modal>
    </div>
  );
};

export default AddCategoryModal;
