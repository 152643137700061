import axios from "axios";
import addQuery from "../utils/addQuery";
import {
  CategoryDetail,
  ProductCategoryCreateInput,
  ProductCategoryUpdateInput,
} from "../types/category.type";

export const getCategories = (queryParams?: {
  [k: string]: any;
}): Promise<{ count: number; data: CategoryDetail[] }> => {
  const url = addQuery("/productCategories", queryParams);
  return axios.get(url).then((res) => res.data);
};

export const addCategory = (catData: ProductCategoryCreateInput) =>
  axios
    .post("/productCategories", {
      name: catData.name,
      description: catData.description,
      fields: { createMany: { data: catData.fields, skipDuplicates: true } },
      types: { createMany: { data: catData.types, skipDuplicates: true } },
    })
    .then((res) => res.data);

export const updateCategory = (data: {
  id: number | null;
  catData: ProductCategoryUpdateInput;
}) =>
  axios
    .patch(`/productCategories/${data.id}`, {
      name: data.catData.name,
      description: data.catData.description,
      isActive: data.catData.isActive,
      fields: {
        createMany: { data: data.catData.fields, skipDuplicates: true },
      },
      types: {
        createMany: { data: data.catData.types, skipDuplicates: true },
      },
    })
    .then((res) => res.data);

export const deleteCategory = (id: number) =>
  axios.delete(`/productCategories/${id}`).then((res) => res.data);
