import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import useAuthStore from "../authStore";
import Layout from "./Layout";

const PrivateRoute: React.FC<RouteProps> = ({ children }) => {
  const token = useAuthStore((state) => state.token);
  if (!token) return <Redirect to="/login" />;
  return (
    <Route>
      <Layout>{children}</Layout>
    </Route>
  );
};

export default PrivateRoute;
