import React, { ReactElement } from "react";
import Logo from "../assets/images/logo.png";

const Dashboard: React.FC = (): ReactElement => {
  return (
    <div className="h-full w-full p-7">
      <div className="h-full w-full border-4 border-dashed rounded border-gray-300 flex flex-col justify-center items-center opacity-50">
        <img src={Logo} width="300" alt="logo" className="mb-10" />
      </div>
    </div>
  );
};

export default Dashboard;
