import React, { ReactElement } from "react";
import NavUserInfo from "./NavUserInfo";

const Header: React.FC = (): ReactElement => {
  return (
    <div className="h-20 bg-white shadow-sm border-b-2 flex justify-end items-center">
      <NavUserInfo />
    </div>
  );
};

export default Header;
