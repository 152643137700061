import React, { ReactElement } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout: React.FC<any> = ({ children }): ReactElement => {
  return (
    <div className="relative min-h-screen flex">
      <Sidebar />
      <div className="flex-1 flex flex-col h-screen bg-gray-100 overflow-y-hidden">
        <Header />
        <div className="flex-1 overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
