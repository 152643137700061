import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";

interface IFieldList {
  id?: number;
  fieldId: number;
  detail: string;
}

interface Props {
  fields: { id: number; name: string }[] | undefined;
  setFieldDetail: React.Dispatch<React.SetStateAction<IFieldList[]>>;
  details?: { id: number; fieldId: number; detail: string }[];
}

const ProductCategoryField: React.FC<Props> = ({
  fields,
  setFieldDetail,
  details,
}) => {
  const [fieldList, setFieldList] = useState<IFieldList[]>([]);
  useEffect(() => {
    setFieldList([]);
    if (fields && fields.length && details && details.length) {
      let fieldSetup: IFieldList[] = [];
      fields.forEach((e) => {
        const fd = details.find((d) => d.fieldId === e.id);
        if (fd) {
          fieldSetup.push({ id: fd.id, fieldId: e.id, detail: fd.detail });
        } else {
          fieldSetup.push({ fieldId: e.id, detail: "" });
        }
      });
      setFieldList(fieldSetup);
    } else if (fields && fields.length) {
      let fieldSetup: IFieldList[] = [];
      fields.forEach((e) => {
        fieldSetup.push({ fieldId: e.id, detail: "" });
      });
      setFieldList(fieldSetup);
    } else setFieldList([]);
  }, [fields, details]);

  const handleFieldChange = (e: any, index: number) => {
    const { value } = e.target;
    const list = [...fieldList];
    list[index]["detail"] = value;
    setFieldList(list);
    setFieldDetail(list);
  };

  return (
    <>
      <div className="mt-3.5 w-full">
        {fields && fields.length ? (
          <>
            <p className="text-blue-500 font-medium">Category Product Field</p>
            <div className="space-y-3.5 mt-3.5 overflow-x-auto py-1">
              {fieldList.length &&
                fields.map((field, i) => (
                  <TextField
                    // autoFocus={i === 0}
                    id={`f-${i}`}
                    inputProps={{ maxLength: 65000 }}
                    key={i}
                    value={(fieldList[i] && fieldList[i].detail) || ""}
                    onChange={(e) => handleFieldChange(e, i)}
                    className="w-full"
                    label={field.name}
                    variant="outlined"
                  />
                ))}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default ProductCategoryField;
