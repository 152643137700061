import axios from "axios";
import { ProductCreateInput, ProductUpdateInput } from "../types/product.type";
import addQuery from "../utils/addQuery";

export const getProducts = (queryParams?: {
  [k: string]: any;
}): Promise<{ count: number; data: any }> => {
  const url = addQuery("/products", queryParams);
  return axios.get(url).then((res) => res.data);
};

export const addProduct = (productData: ProductCreateInput) =>
  axios.post("/products", productData).then((res) => res.data);

export const updateProduct = (data: ProductUpdateInput) =>
  axios.patch(`/products/${data.id}`, data.data).then((res) => res.data);

export const deleteProduct = (id: number) =>
  axios.delete(`/products/${id}`).then((res) => res.data);
