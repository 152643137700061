const addQuery = (url: string, query?: { [k: string]: any }) => {
  if (query) {
    Object.entries(query).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        let symbol = url.includes("?") ? "&" : "?";
        url += `${symbol}${key}=${value}`;
      }
    });
  }
  return url;
};

export default addQuery;
