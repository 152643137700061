import {
  Button,
  IconButton,
  ListItemSecondaryAction,
  TextField,
  ListItemText,
} from "@material-ui/core";
import React, { useState } from "react";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

interface Props {
  fields: { name: string }[];
  setFields: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
      }[]
    >
  >;
}

const CategoryFieldList: React.FC<Props> = ({ fields, setFields }) => {
  const [newField, setNewField] = useState("");
  const handleDelete = (name: string) => {
    const filteredDelete = fields.filter((item) => item.name !== name);
    setFields(filteredDelete);
  };
  const handleAdd = () => {
    if (!newField) return;
    if (fields.some((f) => f.name === newField)) return;
    const fieldObj = { name: newField };
    setFields((prevState) => [...prevState, fieldObj]);
    setNewField("");
  };
  return (
    <div className="flex-1 overflow-x-scroll">
      <p className="text-blue-500 font-medium">Category Product's Field</p>
      <div className="mt-3 flex space-x-3 items-center mb-4">
        <TextField
          id="nfield"
          label="New Field"
          variant="outlined"
          className="w-full"
          placeholder="Product Field eg. coverage, etc"
          value={newField}
          onChange={(e) => setNewField(e.target.value)}
        />
        <Button
          onClick={handleAdd}
          color="primary"
          variant="contained"
          className="focus:outline-none h-10"
          startIcon={<AddCircleOutlineOutlinedIcon />}
        >
          ADD
        </Button>
      </div>
      <List
        dense={true}
        className="h-40 overflow-y-auto bg-gray-100 py-2  rounded-md"
      >
        {fields
          .map((f, i) => (
            <ListItem key={i}>
              <ListItemText primary={f.name} />
              <ListItemSecondaryAction>
                {"id" in f ? null : (
                  <IconButton
                    onClick={() => handleDelete(f.name)}
                    edge="end"
                    aria-label="delete"
                    className="focus:outline-none"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))
          .reverse()}
      </List>
    </div>
  );
};

export default CategoryFieldList;
