import { Button } from "@material-ui/core";
import { ReactComponent as AddBoxIcon } from "../assets/svgs/addbox-icon.svg";
import AppTable from "../components/app/AppTable";
import React, { useState } from "react";
import AddAppModal from "../components/app/AddAppModal";
import { useQuery } from "react-query";
import * as api from "../services/app.service";
import EditAppModal from "../components/app/EditAppModal";

export default function SuggestApp() {
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState<any | null>(null);

  const { data: appTypes } = useQuery(["appType"], () => api.getAppsType());
  const { data: appCat } = useQuery(["appCat"], () => api.getAppsCat());

  const handleEdit = (detail: any) => {
    setEditData(detail);
    setEditOpen(true);
  };

  return (
    <div className="h-full w-full px-10 text-gray-700">
      <AddAppModal
        appCategories={appCat}
        appTypes={appTypes}
        open={addOpen}
        handleClose={() => setAddOpen(false)}
      />
      <EditAppModal
        appCategories={appCat}
        appTypes={appTypes}
        open={editOpen}
        handleClose={() => setEditOpen(false)}
        detail={editData}
      />
      <div className="w-full flex justify-between py-6 items-center">
        <p className="text-3xl text-blue-500 font-medium select-none">
          App List
        </p>
        <Button
          onClick={() => setAddOpen(true)}
          variant="contained"
          className=" focus:outline-none"
          color="primary"
          endIcon={<AddBoxIcon />}
        >
          ADD APP
        </Button>
      </div>
      <AppTable editFunc={handleEdit} />
    </div>
  );
}
